var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "count" },
    [
      _c(
        "div",
        {
          staticClass: "f14 pl50 pt10 pb10  g6 bbe name",
          staticStyle: { "line-height": "33px" },
        },
        [
          _c("el-checkbox", {
            attrs: { indeterminate: _vm.isIndeterminate },
            on: { change: _vm.handleCheckAllChange },
            model: {
              value: _vm.checkAll,
              callback: function ($$v) {
                _vm.checkAll = $$v
              },
              expression: "checkAll",
            },
          }),
          _vm._v("\n    " + _vm._s(_vm.staffList.name) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.handleCheckedOptionsChange },
          model: {
            value: _vm.checkedOptions,
            callback: function ($$v) {
              _vm.checkedOptions = $$v
            },
            expression: "checkedOptions",
          },
        },
        _vm._l(_vm.staffList.user_arr, function (x) {
          return _c(
            "el-checkbox",
            {
              key: x.id,
              staticClass: "db bbe name child pb15",
              staticStyle: {
                width: "100%",
                "margin-left": "0",
                "line-height": "33px",
              },
              attrs: { label: x.id },
            },
            [_c("div", { staticClass: "b g0" }, [_vm._v(_vm._s(x.realname))])]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }