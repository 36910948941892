import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SelectAllBlock',
  props: {
    index: {
      type: Number,
      required: true
    },
    staffList: {
      type: Object,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      checkAll: false,
      checkedOptions: [],
      options: [],
      isIndeterminate: false
    };
  },
  computed: {},
  watch: {
    checkedOptions: function checkedOptions(newV) {
      this.$emit('assign-options', {
        index: this.index,
        arr: newV
      });
    }
  },
  created: function created() {
    var _this = this;
    if (this.staffList.user_arr != undefined) {
      this.staffList.user_arr.forEach(function (e) {
        _this.options.push(e.id);
      });
    }
  },
  mounted: function mounted() {},
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.checkedOptions = val ? this.options : [];
      this.isIndeterminate = false;
    },
    handleCheckedOptionsChange: function handleCheckedOptionsChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    }
  }
};